import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as dbActions from "../../../redux/actions/databaseActions";
import excell from "../../../assets/pivot_icon.jpg";

const PivotButton = ({ setCurrentPage, isActive }) => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.current);
  const classes = isActive
    ? "export-to-excell-button button-is-active"
    : "export-to-excell-button";
  return (
    <div
      onClick={() => {
        setCurrentPage(3);
        dispatch(dbActions.setReports2([]));
        dispatch(dbActions.getReports2(current.currentCompanyId));
      }}
      className={classes}
    >
      <img src={excell} alt="excell" />
      <p>Pivot Report</p>
    </div>
  );
};

export default PivotButton;
