import React from "react";

import { Card } from "@lightspeed/flame/Card";

import { Bone } from "@lightspeed/cirrus/Bone";
import { Table, Td, Tr } from "@lightspeed/cirrus-table";

const Loader = ({ location }) => {
  return !location ? (
    <Card top="true">
      <Table>
        <tbody>
          {"1 2 3 4 5 6 7 8 9 10".split(" ").map(() => (
            <Tr key={Math.random() * 9999}>
              <Td>
                <Bone />
              </Td>
              <Td>
                <Bone />
              </Td>
              <Td>
                <Bone />
              </Td>
              <Td>
                <Bone />
              </Td>
              <Td textAlign="right">
                <Bone />
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </Card>
  ) : (
    <div className="loader-no-item">
      <h3>Please wait...</h3>
    </div>
  );
};

export default Loader;
