//import React, { useState } from "react";

import { Table, Thead, Tr, Th } from "@lightspeed/cirrus-table";
import { Card } from "@lightspeed/flame/Card";

import TBody from "./reportTable/TBody";
import Loader from "../../Loader";

import { useSelector } from "react-redux";

const ReportTable = () => {
  const ui = useSelector((state) => state.ui);

  if (ui.downloadReports) {
    return <Loader />;
  }

  return (
    <Card top="true">
      <Table>
        <Thead>
          <Tr className={`${ui.theme === "flame" ? "" : "dark-table-fake"}`}>
            <Th className="table-th-sticky" width="10%">
              Company Id
            </Th>
            <Th className="table-th-sticky" width="15%">
              Resident Id
            </Th>
            <Th className="table-th-sticky" width="20%">
              Resident Name
            </Th>
            <Th className="table-th-sticky" width="20%">
              Property Name
            </Th>
            <Th className="table-th-sticky" width="20%">
              Charge Name
            </Th>
            <Th className="table-th-sticky" width="15%">
              Create Time
            </Th>
            <Th className="table-th-sticky" width="15%">
              Service Name
            </Th>
            <Th className="table-th-sticky" width="5%">
              Running Total
            </Th>
            <Th className="table-th-sticky" width="10%">
              Charge Code
            </Th>
            <Th className="table-th-sticky" width="5%">
              Total Price
            </Th>
            <Th className="table-th-sticky" width="5%">
              Amount
            </Th>
            <Th className="table-th-sticky" width="10%">
              Srv
            </Th>
            <Th className="table-th-sticky" width="5%">
              Days in Month
            </Th>
            <Th className="table-th-sticky" width="5%">
              Exceded
            </Th>
          </Tr>
        </Thead>
        <TBody />
      </Table>
    </Card>
  );
};

export default ReportTable;
