import React from "react";

import ReportTable from "./tables/ReportTable";
import UsersTable from "./tables/UsersTable";
import PivotTable from "./tables/PivotTable";

const TableContent = ({ currentPage }) => {
  return (
    <div className="table-content">
      {currentPage === 1 && <ReportTable />}
      {currentPage === 2 && <UsersTable />}
      {currentPage === 3 && <PivotTable />}
    </div>
  );
};

export default TableContent;
