import axios from "axios";
import { serverUrl } from "../../serverUrl";
import {
  setDownloadShops,
  setDownloadReports,
  setDownloadReports2,
  setDownloadResidents,
  setDownloadDistinct,
} from "./uiActions";
import { nanoid } from "nanoid";

const customSortCompanyName = (a, b) => {
  const companyA = a.company_name.toLowerCase();
  const companyB = b.company_name.toLowerCase();

  let comparison = 0;
  if (companyA > companyB) {
    comparison = 1;
  } else if (companyA < companyB) {
    comparison = -1;
  }
  return comparison;
};
const customSortResidentName = (a, b) => {
  const companyA = a.resident_name.toLowerCase();
  const companyB = b.resident_name.toLowerCase();

  let comparison = 0;
  if (companyA > companyB) {
    comparison = 1;
  } else if (companyA < companyB) {
    comparison = -1;
  }
  return comparison;
};

export const getShops = (token_id, user_id, company_id) => {
  return (dispatch) => {
    dispatch(setDownloadShops(true));
    const config = {
      method: "get",
      url: `${serverUrl}/yardi/shop`,
      headers: {
        "X-Auth-Token": token_id,
        "x-user-id": user_id,
        "x-company-id": company_id,
      },
    };

    axios(config)
      .then((resp) => {
        const res = resp.data.sort(customSortCompanyName);

        dispatch(setDownloadShops(false));
        dispatch(setShops(res));
        dispatch(setCurrentCompanyId(res[1].company_id));
        dispatch(getDistinct(res[1].company_id));
        dispatch(getResidents(res[1].company_id));
        dispatch(getReports(res[1].company_id));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setDownloadShops(false));
      });
  };
};
export const getReports = () => {
  return (dispatch, getState) => {
    dispatch(setDownloadReports(true));

    const year = getState().control.selectDate.split("-")[0];
    const month = getState().control.selectDate.split("-")[1];
    const { selectValue } = getState().control;
    const { userId } = getState().userState;
    const { currentCompanyId } = getState().current;

    const config = {
      method: "get",
      url: `${serverUrl}/yardi/report`,
      headers: {
        "X-Auth-Token": "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
        "x-resident-id": selectValue === null ? "0" : selectValue.value,
        "x-user-id": userId,
        "x-company-id": currentCompanyId,
        "x-year": year,
        "x-month": month,
      },
    };
    axios(config)
      .then((resp) => {
        dispatch(setDownloadReports(false));
        const res = resp.data;
        dispatch(
          setReports(res.map((item) => ({ ...item, uniqId: nanoid() })))
        );
      })
      .catch((err) => {
        dispatch(setDownloadReports(false));
        console.log(err);
      });
  };
};

export const getReports2 = () => {
  return (dispatch, getState) => {
    dispatch(setDownloadReports2(true));

    const year = getState().control.selectDate.split("-")[0];
    const month = getState().control.selectDate.split("-")[1];
    //const { selectValue } = getState().control;
    const { userId } = getState().userState;
    const { currentCompanyId } = getState().current;

    const config = {
      method: "get",
      url: `${serverUrl}/yardi/report2`,
      headers: {
        "X-Auth-Token": "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
        "x-resident-id": "0",
        "x-user-id": userId,
        "x-company-id": currentCompanyId,
        "x-year": year,
        "x-month": month,
      },
    };
    axios(config)
      .then((resp) => {
        dispatch(setDownloadReports2(false));
        const res = resp.data;
        dispatch(
          setReports2(res.map((item) => ({ ...item, uniqId: nanoid() })))
        );
      })
      .catch((err) => {
        dispatch(setDownloadReports2(false));
        console.log(err);
      });
  };
};

export const getResidents = () => {
  return (dispatch, getState) => {
    dispatch(setDownloadResidents(true));
    const { currentCompanyId } = getState().current;
    const { userId } = getState().userState;

    const config = {
      method: "get",
      url: `${serverUrl}/yardi/residents`,
      headers: {
        "X-Auth-Token": "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
        "x-user-id": userId,
        "x-company-id": currentCompanyId,
      },
    };
    axios(config)
      .then((resp) => {
        dispatch(setDownloadResidents(false));
        dispatch(setResidents(resp.data));
      })
      .catch((err) => {
        dispatch(setDownloadResidents(false));
        console.log(err);
      });
  };
};
export const getDistinct = () => {
  return (dispatch, getState) => {
    dispatch(setDownloadDistinct(true));
    const { currentCompanyId } = getState().current;
    const { userId } = getState().userState;

    const config = {
      method: "get",
      url: `${serverUrl}/yardi/residentsdistinct`,
      headers: {
        "X-Auth-Token": "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
        "x-user-id": userId,
        "x-company-id": currentCompanyId,
      },
    };

    axios(config)
      .then((resp) => {
        dispatch(setDownloadDistinct(false));
        dispatch(setDistinct(resp.data.sort(customSortResidentName)));
      })
      .catch((err) => {
        dispatch(setDownloadDistinct(false));
        console.log(err);
      });
  };
};

export const setCurrentCompanyId = (id) => ({
  type: "SET_CURRENT_COMPANY_ID",
  payload: id,
});
const setShops = (shops) => ({ type: "SET_SHOPS", payload: shops });
export const setReports = (reports) => ({
  type: "SET_REPORTS",
  payload: reports,
});
export const setReports2 = (reports) => ({
  type: "SET_REPORTS_2",
  payload: reports,
});
export const setResidents = (residents) => ({
  type: "SET_RESIDENTS",
  payload: residents,
});
export const setDistinct = (distinct) => ({
  type: "SET_DISTINCT",
  payload: distinct,
});
