import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import GlobarError from "./GlobalError";

//css
import "./css/root.css";
import "./css/index.css";
import "./css/login.css";
import "./css/dash.css";
import "./css/sidebar.css";
import "./css/location.css";
import "./css/dark.css";
import "./css/loader.css";
import "./css/control-panel.css";
import "./css/row.css";

//redux
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./redux/reducers/rootReducer";
import thunk from "redux-thunk";
// import logger from "redux-logger";

// const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, middleware);

ReactDOM.render(
  <>
    <GlobarError>
      <Provider store={store}>
        <App />
      </Provider>
    </GlobarError>
  </>,
  document.getElementById("root")
);
