import React from "react";
import { useDispatch } from "react-redux";

import * as dbActions from "../../../redux/actions/databaseActions";

const SideList = ({ label, setCurrentPage, currentPage, pageN, svg }) => {
  const dispatch = useDispatch();

  return (
    <div className="side-list-button">
      <div
        onClick={(_) => {
          setCurrentPage(pageN);
          if (pageN === 1) {
            dispatch(dbActions.setReports([]));
            dispatch(dbActions.getReports());
            dispatch(dbActions.setDistinct([]));
            dispatch(dbActions.getDistinct());
          }
          if (pageN === 2) {
            dispatch(dbActions.setResidents([]));
            dispatch(dbActions.getResidents());
          }
        }}
        className={`side-label ${
          currentPage === pageN ? "side-list-active" : ""
        }`}
      >
        <img src={svg} alt="icon" className="side-svg" />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default SideList;
