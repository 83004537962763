import React from "react";

import { getDate } from "../../../../utils/dateUtil";

import { Td, Tr } from "@lightspeed/cirrus-table";

import { useSelector } from "react-redux";
const Row = ({ item }) => {
  const ui = useSelector((state) => state.ui);
  //const database = useSelector((state) => state.database);

  // const normalAccount = item.account === null ? "" : item.account;
  // const normalAccountDescription =
  //   item.account_description === null ? "" : item.account_description;

  return (
    <Tr
      //className="report-table-tr"
      className={`${ui.theme === "flame" ? "" : "dark-table-row"}`}
    >
      <Td className="report-table-td">{item.company_id}</Td>
      <Td className="report-table-td">{item.resident_id}</Td>
      <Td className="report-table-td">{item.resident_name}</Td>
      <Td className="report-table-td">{item.comunity_name}</Td>
      <Td className="report-table-td">{item.charge_name}</Td>
      <Td className="report-table-td">{getDate(item.create_time)}</Td>
      <Td className="report-table-td">{item.service_name}</Td>
      <Td className="report-table-td">{item.running_total}</Td>
      <Td className="report-table-td">{item.chargecode}</Td>
      <Td className="report-table-td">{item.total_price}</Td>
      <Td className="report-table-td">{item.amount}</Td>
      <Td className="report-table-td">{item.srv}</Td>
      <Td className="report-table-td">{item.days_in_month}</Td>
      <Td className="report-table-td">{item.exceded}</Td>
    </Tr>
  );
};

export default Row;
