import React from "react";

import excell from "../../../assets/excell.png";

const Excell = ({ onClick }) => {
  return (
    <div onClick={onClick} className="export-to-excell-button">
      <img src={excell} alt="excell" />
      <p>Export to Excel</p>
    </div>
  );
};

export default Excell;
