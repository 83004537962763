import React from "react";

import * as dbActions from "../../redux/actions/databaseActions";
import * as uiActions from "../../redux/actions/uiActions";

import { Input } from "@lightspeed/flame/Input";
import { Card } from "@lightspeed/flame/Card";

import { useSelector, useDispatch } from "react-redux";

const ControlPanel = () => {
  const control = useSelector((state) => state.control);
  const current = useSelector((state) => state.current);
  //const reports2 = useSelector((state) => state.reports2State);
  const dispatch = useDispatch();

  const handleDateChange = (e) => {
    dispatch(uiActions.setSelectDate(e.target.value));
    dispatch(dbActions.setReports2([]));
    dispatch(dbActions.getReports2(current.currentCompanyId));
  };

  return (
    <div className="control-panel">
      <Card className="control-panel-card">
        <div className="flex" />
        <div className="date-wrapper">
          <Input
            type="month"
            min="2018-03"
            value={control.selectDate}
            onChange={handleDateChange}
          />
        </div>
      </Card>
    </div>
  );
};

export default ControlPanel;
