import axios from "axios";
import { serverUrl } from "../../serverUrl";

import * as databaseActions from "../actions/databaseActions";

export const getUser = ({ username, password, setNotification }) => {
  return (dispatch) => {
    const config = {
      method: "get",
      url: `${serverUrl}/yardi/login`,
      headers: {
        "X-Auth-Token": "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
        "X-Username": username,
        "X-Password": password,
      },
    };
    axios(config)
      .then((resp) => {
        const res = resp.data[0];
        dispatch(setUser(res));
        dispatch(
          databaseActions.getShops(
            "wqeafr34w=?*{}][2wSDFA436$%&T$#swts4eEW$T",
            res.user_id,
            res.company_id
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setNotification({ show: true, msg: "Incorrect username or password" });
      });
  };
};

const setUser = (userData) => ({ type: "SET_USER", payload: userData });
